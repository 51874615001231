/**
 * @license
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// Fetch
import 'whatwg-fetch';

// Promise
import 'promise-polyfill/lib/polyfill';

// ES6
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/object/assign';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/repeat';
import 'core-js/features/symbol';
import 'core-js/features/symbol/iterator';
